<div class="modal-container" *ngIf="activeBreakPoint$ | async">
  <div class="modal-content-wrapper">
    <div class="close-modal">
      <img src="assets/svg/close.svg" class="pointer" alt="Close" aria-label="Close" (click)="closeModal()" />
    </div>
    <br />
    <div class="modal-title">
      <h2>there’s no <span class="bold-text">5G</span> in your area yet</h2>
      <p *ngIf="(selectedUiMode$ | async) === 'consumer'">
        But you can still get rain<b class="bold-text">one home</b> with unlimited 4G wifi.
      </p>
      <p *ngIf="(selectedUiMode$ | async) === 'sme'">
        But you can still get rain<b class="bold-text">one work</b> with unlimited 4G wifi.
      </p>
    </div>
    <div class="modal-image-wrapper">
      <div class="product-description-wrapper" *ngIf="(selectedUiMode$ | async) === 'sme'">
        <img *ngIf="(activeBreakPoint$ | async) !== 'mobile'" class="rainOne-img"
          src="/assets/images/rain-101/coverage-modal/No_coverage_work.png"
          alt="not in coverage "
        />
        <div *ngIf="(activeBreakPoint$ | async) !== 'mobile'" class="details-nocoverage">
          <p class="details-nocoverage-heading"><span class="bold-text">unlimited</span> 4G work wifi
              <br>
              +<span class="bold-text">FREE </span> monthly calls & data for 5 phones
          </p>
          <ul class="details-list">
              <li> Unlimited 4G work wifi</li>
              <li> <span class="bold-text">the101</span>™ 5G smart router free-to-use</li>
              <li> <span class="bold-text">5 FREE</span> 4G mobile SIMs</li>
              <li> Each SIM comes with:
                  <br>
                  2GB | 60min | 100sms <span class="bold-text">FREE</span> every month
              </li>
          </ul>
          <div class="rain-one-price-container">
              <div class="price-wrapper">
                  <span class="price">R795</span><br>
                  <span class="month-to-month">month-to-month</span>
              </div>
          </div>
        </div>
        <img
          *ngIf="(activeBreakPoint$ | async) === 'mobile'"
          src="/assets/images/rain-101/coverage-modal/Work_no_coverage.webp"
          alt="not in coverage "
        />
      </div>

      <div class="product-description-wrapper" *ngIf="(selectedUiMode$ | async) === 'consumer'">
        <img
          *ngIf="(activeBreakPoint$ | async) !== 'mobile'" class="rainOne-img"
          src="/assets/images/rain-101/coverage-modal/No_coverage_home.png"
          alt="not in coverage "
        />
        <div *ngIf="(activeBreakPoint$ | async) !== 'mobile'" class="details-nocoverage">
          <p class="details-nocoverage-heading"><span class="bold-text">unlimited</span> 4G home wifi
              <br>
              +<span class="bold-text">FREE </span> monthly calls & data for 2 phones
          </p>
          <ul class="details-list">
              <li> Unlimited 4G work wifi</li>
              <li> <span class="bold-text">the101</span>™ 5G smart router free-to-use</li>
              <li> <span class="bold-text">2 FREE</span> 4G mobile SIMs</li>
              <li> Each SIM comes with:
                  <br>
                  2GB | 60min | 100sms <span class="bold-text">FREE</span> every month
              </li>
          </ul>
          <div class="rain-one-price-container">
              <div class="price-wrapper">
                  <span class="price">R625</span><br>
                  <span class="month-to-month">month-to-month</span>
              </div>
          </div>
        </div>
        <img
          *ngIf="(activeBreakPoint$ | async) === 'mobile'"
          src="/assets/images/rain-101/coverage-modal/Home_no_coverage.webp"
          alt="not in coverage "
        />
      </div>

      <div class="bottom-sub-content">
        <p *ngIf="(selectedUiMode$ | async) === 'consumer'">
          As soon as you’re in 5G coverage, we’ll upgrade you. Then you’ll have the option to <b class="bold-text">speed up</b> your home wifi. If
          you’d prefer to wait for 5G, tap <b class="bold-text">‘notify me’</b> and we’ll let you know the moment it reaches you.
        </p>
        <p *ngIf="(selectedUiMode$ | async) === 'sme'">
          As soon as you’re in 5G coverage, we’ll upgrade you. Then you’ll have the option to <b class="bold-text">speed up</b>
          your work wifi. If you’d prefer to wait for 5G, tap
          <b class="bold-text">‘notify me’</b> and we’ll let you know the moment it reaches you.
        </p>
        <div class="terms-and-conditions">
          <span class="terms" (click)="openTerms()">Ts & Cs apply</span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <div class="left-buttons">
        <button (click)="onNotifyMe()" class="btn btn-sm btn-transparent border-primary text-primary mr-4 notify-btn">notify me</button>
      </div>
      <div class="right-buttons">
        <app-support-button
          [disabled]="isTicketCreationAllowed$ | async"
          btnClass="btn btn-sm btn-transparent border-primary text-primary on-register "
          [dataId]="'btn-click'"
          [isPromo]="false"
          fromPage="coverage check"
          fromButton="productCallMe"
          title="request sales call"
          id="btn-cust"
          [subject]="'rain sales support #'"
          [bypassModal]="false"
          [productId]="rainOneBundle.id"
          [showSignIn]="false"
          (click)="onOpenSupport()"
        >
        </app-support-button>
        <button (click)="onContinueToBuy('30')" class="btn btn-sm btn-primary text-white buy-btn">buy</button>
      </div>
    </div>
  </div>
</div>
