import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree
} from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthenticationService } from "@services/auth.service";
import { ResetUserBillingAccountStatus } from '../core/store/actions/billing.actions';
import { ResetOrders } from "../store/actions/order.actions";
import { ClearServicesState } from "../store/actions/services.actions";
import { Observable } from "rxjs";
import { AssignAndClaimSimState } from "../store/state/assign-claim-sim.state";

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(private router: Router,private store: Store, private auth: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.auth.isSignedIn) {
      try {
        localStorage.setItem("intended_route", state.url);
        this.store.dispatch([
          new ResetUserBillingAccountStatus(),
          new ResetOrders(),
          new ClearServicesState(),
        ]);
      } catch (error) {
        console.error("Error during authentication handling:", error);
      } finally {
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: state.url },
        });
      }
    
      return false; 
    }
    return true;
  }
}

@Injectable()
export class GuestGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private router: Router, private auth: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    if (this.auth.isSignedIn) {

      this.router.navigateByUrl("/");

      return false;
    }

    return true;
  }
}

@Injectable()
export class SaIDGuard implements CanActivate {
  constructor(private router: Router,private store: Store){}
   path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     if(this.store.selectSnapshot(AssignAndClaimSimState.getSaId).length) 
     {
      return true  
    }
    this.router.navigateByUrl('home') 
    return false
  }
}

