import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ConfigService } from '@services/config.service';
import { DataLayerService } from '@services/data-layer.service';
import { AuthState } from 'src/app/core/store/state/auth.state';

@Component({
  selector: 'rain-nvidia-sold-out',
  templateUrl: './nvidia-sold-out-modal.component.html',
  styleUrls: ['./nvidia-sold-out-modal.component.scss']
})
export class NvidiaSoldOutModalComponent implements OnInit {
  
  contactForm: FormGroup;
  loading: boolean = false;
  success: boolean = false;
  name:string = "";

  userStateInformation  = this.store.selectSnapshot(AuthState.getDigitalIdentity);

  nameValidationMessages: ValidationErrors = {
    required: 'Name is required.',
    pattern: 'Name must contain at least 2 characters.'
  };
  emailValidationMessages: ValidationErrors = {
    required: 'Email is required.',
    pattern: 'Please enter a valid email address.'
  };
  cellNumberValidationMessages: ValidationErrors = {
    required: 'Contact number is required.',
    pattern: 'Please enter a valid contact number.'
  };

  constructor(
    private fb:FormBuilder,
    private http: HttpClient,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private dataLayerService: DataLayerService,
    private store: Store
    ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.contactForm = this.fb.group({
      name: [this.userStateInformation?.individualIdentified?.name ?? '', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
      number: [this.userStateInformation?.individualIdentified?.mobile_number ?? '', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])],
      email: [this.userStateInformation?.loginId ?? '', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])],
      tierValue: ['', Validators.required]
    });
  }
  
  close() {
    this.activeModal.close();
  }

  sendResult() {

    this.loading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    const formValues = this.contactForm.value;
    this.name = formValues.name;
      // this.
      const payload = {
        full_name: formValues.name,
        email: formValues.email,
        contact_number: formValues.number,
        location: "n/a",
        product: `nvidia_${formValues.tierValue}`
      }
      this.http.post(`${this.configService.BASE_API_URL}/waiting-list/add`, payload, httpOptions).subscribe(() => {
          this.dataLayerService.addToNvidiaWaitingList()
          this.success = true;
          this.loading = false;
      })
  }
}
