import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { RainOneProduct } from 'src/app/core/interfaces/rain-one-products.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ChangeWifiSpeed, ClearAllVas, EmptyCart, SetSelectedRouterSkin, SetXtenderSpeed, StartRainOnePurchase } from 'src/app/store/actions/cart.action';
import { UpdateRainOneBundleDescription } from 'src/app/store/actions/copy.actions';
import { CartState } from 'src/app/store/state/cart.state';
import { ProductState } from 'src/app/store/state/product.state';
import { VasRemovalDisclaimerModalComponent } from '../vas-removal-disclaimer-modal/vas-removal-disclaimer-modal.component';
import { LimitSalesTicketCreationState } from '@components/sales-modal/store/state/limit-ticket-creation.state';
import { GetTicketCreationTimerStatus } from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import { UIState } from '../../store/state/ui.state';
import { ResetOrders } from 'src/app/store/actions/order.actions';
import { takeUntil } from 'rxjs/operators';
import { UIActions } from '../../store/actions/ui.actions';
import { a30Id, CONFIG_COMMENTS, CONFIG_FRIENDLY_NAME, pro1001ExtId, pro1002ExtId, pro100NoExtId, pro601ExtId, pro602ExtId, pro60NoExtId, proWork1001ExtId, proWork1002ExtId, proWork100NoExtId, proWork601ExtId, proWork602ExtId, proWork60NoExtId, work30Id } from '@models/constants';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'rain-rain-one-out-of-coverage-modal',
  templateUrl: './rain-one-out-of-coverage-modal.component.html',
  styleUrls: ['./rain-one-out-of-coverage-modal.component.scss']
})
export class RainOneOutOfCoverageModalComponent implements OnInit {
  @Select(LimitSalesTicketCreationState.isTicketCreationAllowed) isTicketCreationAllowed$: Observable<boolean>;
  public rainOneBundle: RainOneProduct;
  @Output() continuePurchase: EventEmitter<boolean> = new EventEmitter();
  @Output() notifyMe: EventEmitter<boolean> = new EventEmitter();
  @Select(CoreState.activeBreakpoint) activeBreakPoint$ : Observable<string>
  @Select(UIState.GetUIMode) selectedUiMode$ : Observable<string>;
  private readonly ngDestroy$ = new Subject()
  uiMode = this.store.selectSnapshot(UIState.GetUIMode);
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private router: Router,
    private modalService: NgbModal,
    private cartSVC: CartService

  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.store.dispatch(new GetTicketCreationTimerStatus())
    }, 30000);
    this.rainOneBundle = this.store.selectSnapshot(ProductState.GetRainOneL1);

    this.store.select(UIState.GetUIMode).pipe(takeUntil(this.ngDestroy$)).subscribe(uiMode => {
      this.uiMode = uiMode
  });
  const url = this.router.url.includes('work')

  if (url ) {
      this.store.dispatch(new UIActions.SwitchUIMode({mode: 'sme'}));
  }

  }

  public onNotifyMe() {
    this.notifyMe.emit(true);
    this.continuePurchase.emit(false);
    return this.close();
  }

  public onContinueToBuy(speed?: string) {
    const hasSpeedUp = this.store.selectSnapshot(CartState.hasSpeedUp);
    localStorage.setItem('continue_with_4g', 'true');
    this.store.dispatch(
      new UpdateRainOneBundleDescription({title: 'unlimited 4G Home wifi',speedDescription: 'Best effort 4G speeds', routerDescription: '5G ready free-to-use router'})
    );

    this.close(true);
    if(hasSpeedUp) {
      this.store.dispatch(new ClearAllVas);

      const modalRef = this.modalService.open(VasRemovalDisclaimerModalComponent, {
        size: 'upsell-check' as any,
        windowClass: 'slideInUp',
        centered: true
      });
    }

    const defaultSkin = [{
      id: 'b6afbfca-00b2-4490-a850-88022c74edcc',
      name: 'The 101.a skin - Kristen McClarty',
      type: 'accessory',
      sku: 'RAIN-SKIN-0024',
      config: {
        color: 'Kristen McClarty',
        colorSelector: 'Kristen-McClarty',
        hexColor: 'Kristen-McClarty',
        available: true,
        form_factor: 'the 101.a'
      },
      image: 'assets/images/rain-101/skin-colors/pro-xtender-101a/101/Kristen-McClarty.webp',
      buttonImage: 'assets/images/rain-101/skin-colors/pro-xtender-101a/buttons/101/Kristen-McClarty.webp'
    }]
    
    this.store.dispatch([new ResetOrders(), new SetXtenderSpeed(speed), new SetSelectedRouterSkin(defaultSkin)]);

    let selectedSpeed: string;
    if (this.uiMode === 'consumer') {
        if(!speed.includes('e')) {
            selectedSpeed = speed == '30' ? a30Id : speed == '60' ? pro60NoExtId : pro100NoExtId;
        }
        else {
            if(speed === '601e') {
                selectedSpeed = pro601ExtId;
            }
            if(speed === '602e') {
                selectedSpeed = pro602ExtId;
            }
            if(speed === '1001e') {
                selectedSpeed = pro1001ExtId;
            }
            if(speed === '1002e') {
                selectedSpeed = pro1002ExtId;
            }
        }

    } else if (this.uiMode === 'sme') {
        if(!speed.includes('e')) {
            selectedSpeed = speed == '30' ? work30Id : speed == '60' ? proWork60NoExtId : proWork100NoExtId;
        }
        else {
            if(speed === '601e') {
                selectedSpeed = proWork601ExtId;
            }
            if(speed === '602e') {
                selectedSpeed = proWork602ExtId;
            }
            if(speed === '1001e') {
                selectedSpeed = proWork1001ExtId;
            }
            if(speed === '1002e') {
                selectedSpeed = proWork1002ExtId;
            }
        }
    }

    this.store.dispatch(new EmptyCart());

    this.cartSVC
        .add(selectedSpeed, {
            [CONFIG_FRIENDLY_NAME]: '5G SIM',
            [CONFIG_COMMENTS]: undefined
        })
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(() => {
            this.store.dispatch([new StartRainOnePurchase(selectedSpeed),
                new ChangeWifiSpeed(0)
            ]);
        });
    return;

  }

  onOpenSupport() {
    this.activeModal.close();  
  }

  public close(override = false) {
    this.continuePurchase.emit(override);
    this.activeModal.close();
  }

  public closeModal() {
    this.activeModal.close();
    this.store.dispatch(new EmptyCart());
    this.router.navigateByUrl('/');
  }

  navigate(){
    this.router.navigateByUrl('/legal');
    this.activeModal.close();
  }

  openTerms() {
    this.router.navigateByUrl('/legal?section=terms');
    this.activeModal.close();
  }

}
