import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { Injectable } from "@angular/core";
import { HttpClickToClaimModel } from "../store/interfaces/click-to- claim.interface";

@Injectable({
    providedIn: 'root'
  })

export class ClickToClaimService {

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService
    ) { 

    }

    fetchVoucherWebValues() {
      const base_url = this.configService.BASE_API_URL ? this.configService.BASE_API_URL :'https://prod-bss-api.rain.co.za'
      const key = this.configService.TOGGLE_API_KEY ? this.configService.TOGGLE_API_KEY :'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH'
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              apiKey: key
            })
          };

        const url =`${base_url}/bss-api/v1/voucher-service/voucher/web-values?ck=${Date.now()}`;
        
        return this.httpClient.get(url, httpOptions);
    }

}