import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {BaseComponent} from 'src/app/baseComponent';
import {IUserDetail} from '@models/userDetail';
import {AuthenticationService} from '@services/auth.service';
import {CartItem, CartService} from '@services/cart.service';
import {ElementService} from '@services/element.service';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NavModalComponent} from '../nav-modal/nav-modal.component';
import {DataLayerService} from '@services/data-layer.service';
import {ConfirmSignOutModalComponent} from '@components/confirm-sign-out-modal/confirm-sign-out-modal.component';
import {HideLoader} from 'src/app/shared/store/actions/loader.actions';
import {CoreState} from 'src/app/core/store/state/core.state';
import {
    ChangeRainOneLevel,
    ChangeRainOneVas,
    ChangeWifiSpeed,
    ClearCart,
    EmptyCart,
    RemoveFromCart,
    RemoveWifiSpeed,
    ResetCart
} from 'src/app/store/actions/cart.action';
import {CacheService} from '@services/cache.service';
import {CACHE_CART} from '@models/constants';
import {ProfileService} from '@services/profile.service';
import {UIActions} from 'src/app/shared/store/actions/ui.actions';
import {Observable} from 'rxjs';
import {UIMode, UIState} from 'src/app/shared/store/state/ui.state';
import {SwitchUIModeService} from '@services/switch-uimode.service';
import {ButtonClickedTracking} from 'src/app/store/actions/rain-analytics-tracker.actions';
import {RainAnalyticsTrackerClick} from 'src/app/store/interfaces/rain-analytics-tracker';
import {SSOService} from "../../core/services/sso.service";
import {environment} from "../../../environments/environment";
import {Location} from '@angular/common';
import { BottomSheetState } from 'src/app/v2/store/state/bottom-sheet-state';
import { CloseBottomSheet, ShowBottomSheet } from 'src/app/v2/store/actions/bottom-sheet.actions';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
    public userDetail: IUserDetail;
    @Select(UIState.GetUIMode) selectedUiMode$: Observable<string>;
    @Select(CoreState.activeBreakpoint) breakpoint$: Observable<string>;
    selectedUiMode = this.store.selectSnapshot(UIState.GetUIMode)
    @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
    bottomSheet = this.store.selectSnapshot(BottomSheetState.OpenSheetName)
    uiMode: string;
    isSignedIn: boolean;
    cartCount: number = 0;
    showMenuItems: boolean = true;
    toggleChatBot: boolean;

    scrollToShop: () => void;
    scrollToHome: () => void;
    arrearAmount: number = 0;
    public isDesktop: boolean = false;

    constructor(
        private router: Router,
        private location: Location,
        private authService: AuthenticationService,
        private cartService: CartService,
        private cacheService: CacheService,
        private modalService: NgbModal,
        private store: Store,
        private dataLayerService: DataLayerService,
        private elementService: ElementService,
        private profileService: ProfileService,
        private actions$: Actions,
        private switchUIModeService: SwitchUIModeService,
        private sso: SSOService,
        private activatedRoute: ActivatedRoute
    ) {
        super();

        this.scrollToShop = this.elementService.scrollToShop;

        this.scrollToHome = this.elementService.scrollToHome;
        this.isDesktop = window.innerWidth >= 740;
    }

    ngOnInit() {
        this.store.dispatch(new UIActions.SwitchUIMode({mode: this.selectedUiMode}));
        this.selectedMode$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            this.uiMode = result;
        });
        this.cartCount = this.cartService.getCount();
        this.isSignedIn = this.authService.isSignedIn;

        this.authService.onSignedInStatusChanged.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(resp => {
                this.isSignedIn = resp;
                if (this.isSignedIn) {
                    this.dataLayerService.login();
                    $('#dropdown-login').removeClass('show');
                }
            });

        this.cartService.onUpdated.pipe(takeUntil(this.ngUnsubscribe)).subscribe(count => {
            this.cartCount = count;
        });

        this.cartService.onCleared.pipe(takeUntil(this.ngUnsubscribe)).subscribe(count => {
            this.cartCount = this.cartService.getCount();
            if (!this.cacheService.getObject(CACHE_CART) || this.cacheService.getObject<CartItem[]>(CACHE_CART).length < 1) {
                this.store.dispatch(new ResetCart());
            }
        });


        this.actions$.pipe(
            takeUntil(this.ngUnsubscribe),
            ofActionCompleted(
                RemoveWifiSpeed,
                ChangeWifiSpeed,
                ChangeRainOneLevel,
                RemoveFromCart,
                ChangeRainOneVas,
                ClearCart
            )
        ).subscribe({

            next: () => setTimeout(() => {
                this.cartCount = this.cartService.getCount()
            }, 500)
        });
        this.checkUrl();
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.checkUrl();
            });

        this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {

            const askRainParameter = params.askrain;

            if (askRainParameter === 'open') {
                this.toggleChatBot = true;
                this.goToAskRain();
            }
        })
    }

    checkUrl(): void {
        this.showMenuItems = !window.location.pathname.startsWith('/manage');
    }

    goToAskRain() {  
      this.store.dispatch(new ShowBottomSheet("ask_rain_bot"));
    }
    closeChatBot() {
        this.store.dispatch(new CloseBottomSheet());
        this.toggleChatBot = false;
    }

    onClickSignIn() {
        this.profileService.clearCache();

        this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['sign-in']);

        const rainTrackingClickOj: RainAnalyticsTrackerClick = {
            buttonId: `sign-in`,
            buttonLabel: `sign-in`,
            event: 'btn-click'
        }

        this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
        this.router.navigate(['/login/home']);
    }

    signOut() {
        this.profileService.clearCache();

        const rainTrackingClickOj: RainAnalyticsTrackerClick = {
            buttonId: `sign-out-modal-open`,
            buttonLabel: `sign-out-modal-open`,
            event: 'btn-click'
        }

        this.store.dispatch([
            new HideLoader(),
            new ButtonClickedTracking(rainTrackingClickOj)]);
            
        sessionStorage.removeItem('has_241_promo');

        this.modalService.open(ConfirmSignOutModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
        });
    }

    home() {
        this.elementService.scrollToHome();
    }

    clear() {
        this.store.dispatch(new EmptyCart())
    }

    openCart() {
        if (this.cartService.getCount() === 0) {
            this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['cart-empty']);
        }

        this.router.navigate(['/cart']);
    }

    openSideNav() {
        const modalRef = this.modalService.open(NavModalComponent, {size: <any>'left', windowClass: 'slide-left '});
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isDesktop = window.innerWidth >= 740;
    }

    checkRoute(route: string) {
        const currentUrl = this.location.path();
        return currentUrl.includes(route);
    }

    async goToRainGo() {
        const encodedUrl = await this.sso.createUrl(environment.rainGoSSOUrl);
        window.open(encodedUrl, '_self');
    }
}
