<div class="modal-header border-0">
    <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" data-dismiss="modal" data-target="#thankYouModal"
         (click)="close()"
            src="../../assets/svg/close.svg" />
    </div>
</div>

<div class="modal-body">
    <div *ngIf="!success">
        <div class="modal-body-intro">
            <div class="text-center">
                <div class="text-primary h2 font-weight-semi-bold text-align-left">
                    GeForce <span class="bold">NOW</span> waitlist
                </div>
                <p class="text-align-left pt-10">Please leave your details below and we’ll let you know when it’s time to get your game on.</p>
            </div>
        </div>
        <!-- <re-captcha #recap #captchaRef="reCaptcha" size="invisible" [siteKey]="siteKey"
            (resolved)="$event && recaptchaSubmit($event)">
        </re-captcha> -->
    
        <div class="d-flex justify-content-center">
            <form [formGroup]="contactForm" class="desk-60">
               
                <div class="form-group">
                    <rain-rain-input-sales-modal maxlength="24" [showLabel]="false" placeholder="Name" label="Name" [control]="contactForm.get('name')" [type]="'text'"
                        [messages]="nameValidationMessages">
                    </rain-rain-input-sales-modal>
                </div>
    
                <div class="form-group">
                    <rain-rain-input-sales-modal label="email address" [showLabel]="false" placeholder="Email address" [control]="contactForm.get('email')" [type]="'email'"
                        [messages]="emailValidationMessages">
                    </rain-rain-input-sales-modal>
                </div>
                <div class="form-group">
                    <rain-rain-input-sales-modal label="contact number" [showLabel]="false" placeholder="Contact number" [control]="contactForm.get('number')" [type]="'tel'"
                        [messages]="cellNumberValidationMessages">
                    </rain-rain-input-sales-modal>
                </div>
                <div class="form-group">
                    <p>which plan would you like?</p>
                    <div class="choose-nvidia-plan-wrapper">
                        <div><input type="radio" formControlName="tierValue" value="priority">&nbsp;performance</div>
                        <div><input type="radio" formControlName="tierValue"  value="ultra">&nbsp;ultra</div>
                    </div>
                </div>
                <div class="d-flex justify-content-end my-5">
                    <button [disabled]="contactForm.invalid" 
                        (click)="sendResult()"
                        [className]="(contactForm.invalid ? ' btn-tertiary' : ' btn-primary text-white') + ' btn btn-md'">
                        submit
                        <!-- <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> -->
                    </button>
                </div>
            </form>
          
        </div>
    </div>
    <ng-container *ngIf="success">
        <div class="modal-body-intro">
            <div class="text-center">
                <div class="text-primary h2 font-weight-semi-bold text-align-left">
                    thank you, <span class="bold">{{name}}</span>
                </div>
                <p class="text-align-left pt-10">You're officially in line to join the NVIDIA GeForce NOW Powered by rain. We'll be in touch soon.</p>
            </div>
        </div>
    </ng-container>
    <!-- <div class="loading-wrapper" *ngIf="loading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          
    </div> -->
    <div class="text-disclaimer desk-60 pb-60px">
       This site is protected
        by reCAPTCHA and the Google <a href="/legal?section=policies" target="_blank"
            class="text-primary text-decoration-underline">Privacy Policy</a> and <a href="/legal?section=terms"
            target="_blank" class="text-primary text-decoration-underline">Terms of Service</a> apply.
    </div>
</div>