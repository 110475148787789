<ng-container *ngIf="{ap: ap$ | async} as viewModel">

    <div *ngIf="uiMode !== 'consumer'" [class]="'spacer is-'+viewModel.ap"></div>
    <rain-sticky-nav class="sticky-nav"
                     (requestSalesCallTriggered)="handleRequestSalesCallFromSticky()"
                     (playVideoTrigger)="handlePlayVideoTrigger()">
<!--                     <div *ngIf="clickToClaimAmount['consumer'] != 0 || clickToClaimAmount['sme'] != 0" ButtonBlue class="pl-2 justify-item-left button-wrapper">-->
<!--                        <button (click)="handleRequestSalesCallFromSticky()"  class="btn d-block btn-md btn-primary-outline mb-3 mt-3 button2 next-button" ><span-->
<!--                                class="copy">click & claim</span></button>-->
<!--                    </div>-->
        <div Title class="sticky-nav-grid__content pr-10-custom text-primary hide-mobile">
            <span class="sticky-text-1"><span class="text-primary">rain<span class="font-weight-bold">one</span></span> for<span
                    class="font-weight-bold" *ngIf="(selectedMode$ | async) === 'consumer'">&nbsp;home</span> <span
                    class="font-weight-bold" *ngIf="(selectedMode$ | async) === 'sme'">&nbsp;work</span>&nbsp;&<span
                    class="font-weight-bold">&nbsp;phones</span></span>
        </div>
    </rain-sticky-nav>

    <!-- <rain-product-nav></rain-product-nav> -->

    <ng-template #level30>
        <div class="main-card d-flex flex-column justify-content-center pt-6">
            <div class="cpe-container d-flex">
                <img *ngIf="uiMode === 'consumer'" src="/assets/images/sales2p1/the101_30.webp"
                     alt="artistic wifi router">
                <img *ngIf="uiMode === 'sme'" src="/assets/images/sales2p1/the101_30_5SIM.webp"
                     alt="artistic wifi router">
            </div>
            <div class="speed-text pl-7 ">
                <h1 class="blue-text the101-header"><span class="fw-700">the101</span></h1>
                <p class="blue-text the101-sub-header">up to <span class="font-weight-bold">30 Mbps</span></p>
            </div>
            <hr>
            <div class="pricing-container d-flex flex-column justify-content-center  pl-7">
                <span class="plan-price">{{ uiMode === 'consumer' ? 'R625' : 'R795' }}</span>
                <span class="plan-start-mont">month-to-month</span>
            </div>
            <div class="keep-connected-text pl-7">
                <p class="grey-t">Stay connected with <span class="font-weight-bold">unlimited</span> 5G wifi and
                    <span class="font-weight-bold">FREE</span> calls & data all-in-one</p>
            </div>
            <div class="buy-now-btn-wrapper mb-13 mt-3 d-flex justify-content-center">
                <button class="buy-now-btn btn btn-primary" (click)="addToCart('30')">buy now</button>
            </div>
        </div>
    </ng-template>

    <ng-template #level30Plan>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">your plan includes</span>
        </div>
        <div class="plan-specs d-flex flex-column pt-8" [ngClass]="isPlanSpecs ? 'dropPlan' : 'hidden'">
            <div class="plan-sub-header">
                <h3 class="rain-bt the101-header">the101</h3>
                <p class="">Not just a router, a 5G smart router (free-to-use)</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">up to 30 Mbps</h3>
                <p class="">Download speeds</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt mb-8">unlimited 5G wifi</h3>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">{{ uiMode === 'consumer' ? '2' : '5' }} FREE 4G mobile SIMs</h3>
                <p class="">Each SIM gets <span class="fw-600">2 gigs, 60 minutes & 100 sms</span> FREE every month</p>
            </div>
            <div class="promo-banner">
                <img class="home-page-image br-8"
                     src="/assets/images/sales2p1/promo/desktop-promo-banner.webp"
                     alt="raingo data promo">
            </div>
        </div>
    </ng-template>

    <ng-template #level30Features>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">the101 features</span>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8"
             [ngClass]="isFeatureSpecs === true ? 'dropFeatures' : 'hidden'">
            <div class="img-feature d-flex">
                <img *ngIf="uiMode === 'consumer'" src="/assets/images/sales2p1/the101.webp" alt="artistic wifi router">
                <img *ngIf="uiMode === 'sme'" src="/assets/images/sales2p1/the101_work.webp" alt="artistic wifi router">
            </div>
            <div class="plan-sub-header ">
                <h3 class="rain-bt">compact design</h3>
                <p class="">230mm x 230mm x 56mm</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">touch screen</h3>
                <p class="">1.8” circular display, packed with smart features</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">101 skins</h3>
                <p class="">A range of skins to suit your style</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">5G</h3>
                <p class="">200 MHz 2-carrier aggregation</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">AX3600 wifi 6</h3>
                <p class="mb-40">For improved speed, range & performance</p>
            </div>
            <a href="/the101" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <ng-template #level60>
        <div class="main-card d-flex flex-column justify-content-center pt-6">
            <div class="cpe-container d-flex">
                <img *ngIf="uiMode === 'consumer'" src="/assets/images/sales2p1/the101_pro_60.webp"
                     alt="artistic wifi router">
                <img *ngIf="uiMode === 'sme'" src="/assets/images/sales2p1/the101_pro_60_5SIM.webp"
                     alt="artistic wifi router">
            </div>
            <div class="speed-text pl-7">
                <h1 class="blue-text the101-header"><span class="fw-700">the101 pro</span></h1>
                <p class="blue-text the101-sub-header">up to <span class="font-weight-bold">60 Mbps</span></p>
            </div>
            <hr>
            <div class="pricing-container d-flex flex-column justify-content-center pl-7">
                <span class="plan-price">{{ uiMode === 'consumer' ? 'R825' : 'R995' }}</span>
                <span class="plan-start-mont">month-to-month</span>
            </div>
            <div class="keep-connected-text pl-7">
                <p class="grey-t">Get the speed you need with our smallest, fastest,
                    <span class="font-weight-bold">smartest 5G router.</span></p>
            </div>
            <div class="buy-now-btn-wrapper mb-13 mt-3 d-flex justify-content-center">
                <button class="buy-now-btn" (click)="addToCart('60')">buy now</button>
            </div>
        </div>
    </ng-template>

    <ng-template #level60Plan>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">your plan includes</span>
        </div>
        <div class="plan-specs d-flex flex-column pt-8" [ngClass]="isPlanSpecs ? 'dropPlan' : 'hidden'">
            <div class="plan-sub-header">
                <h3 class="rain-bt the101-header">the101 pro</h3>
                <p class="">Our smallest, fastest, smartest 5G router (free-to-use)</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">up to 60 Mbps</h3>
                <p class="">Download speeds</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt mb-8">unlimited 5G wifi</h3>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">{{ uiMode === 'consumer' ? '2' : '5' }} FREE 4G mobile SIMs</h3>
                <p class="">Each SIM gets <span class="fw-600">2 gigs, 60 minutes & 100 sms</span> FREE every month</p>
            </div>
            <div class="promo-banner">
                <img class="home-page-image br-8"
                     src="/assets/images/sales2p1/promo/desktop-promo-banner.webp"
                     alt="raingo data promo">
            </div>
        </div>
    </ng-template>

    <ng-template #level60Features>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">the101 pro features</span>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8"
             [ngClass]="isFeatureSpecs === true ? 'dropFeatures' : 'hidden'">
            <div class="img-feature d-flex">
                <img src="/assets/images/sales2p1/the101pro_60.webp" alt="artistic wifi router">
            </div>
            <div class="plan-sub-header ">
                <h3 class="rain-bt">ultra compact design</h3>
                <p class="">210mm x 210mm x 48mm</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">large touch screen</h3>
                <p class="">2.1” circular display, with even more features & content.</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">NEW 101 smart skins</h3>
                <p class="">A built-in NFC brings your screen to life</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">5G advanced</h3>
                <p class="">300 MHz 4-carrier aggregation</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">latest wifi 7</h3>
                <p class="">Higher speed & lower latency for better streaming</p>
            </div>
            <a href="/the101" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <ng-template #level100>
        <div class="main-card d-flex flex-column justify-content-center pt-6">
            <div class="cpe-container d-flex">
                <!--                <img *ngIf="uiMode === 'consumer'" src="/assets/images/sales2p1/100+_wih_extender.webp"-->
                <!--                     alt="artistic wifi router">-->
                <!--                     alt="artistic wifi router">                -->
                <img *ngIf="uiMode === 'consumer'" src="/assets/images/sales2p1/the101_pro_100_noxtender.webp"
                     alt="artistic wifi router">
                <img *ngIf="uiMode === 'sme'" src="/assets/images/sales2p1/the101_pro_100_5SIM_noxtender.webp"
                     alt="artistic wifi router">
            </div>
            <div class="speed-text pl-7 ">
                <h1 class="blue-text the101-header"><span class="fw-700">the101 pro</span></h1>
                <p class="blue-text the101-sub-header"><span class="font-weight-bold">unlimited</span> 5G speeds</p>
            </div>
            <hr>
            <div class="pricing-container d-flex flex-column justify-content-center  pl-7">
                <span class="plan-price">{{ uiMode === 'consumer' ? 'R1025' : 'R1195' }}</span>
                <span class="plan-start-mont">month-to-month</span>
            </div>
            <div class="keep-connected-text pl-7">
                <p class="grey-t">Choose <span class="font-weight-bold">unlimited</span> speed with our smallest,
                    fastest,
                    <span class="font-weight-bold">smartest 5G router.</span>
                </p>
            </div>
            <div class="buy-now-btn-wrapper mb-13 mt-3 d-flex justify-content-center">
                <button class="buy-now-btn" (click)="addToCart('100')">buy now</button>
            </div>
        </div>
    </ng-template>

    <ng-template #level100Plan>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">your plan includes</span>
        </div>
        <div class="plan-specs d-flex flex-column pt-8" [ngClass]="isPlanSpecs ? 'dropPlan' : 'hidden'">
            <div class="plan-sub-header">
                <h3 class="rain-bt the101-header">the101 pro</h3>
                <p class="">Our smallest, fastest, smartest 5G router (free-to-use)</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">unlimited 5G speed</h3>
                <p class="">Uncapped speeds, best performance </p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt mb-8">unlimited 5G wifi</h3>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">{{ uiMode === 'consumer' ? '2' : '5' }} FREE 4G mobile SIMs</h3>
                <p class="">Each SIM gets <span class="fw-600">2 gigs, 60 minutes & 100 sms</span> FREE every month</p>
            </div>
            <div class="promo-banner">
                <img class="home-page-image br-8"
                     src="/assets/images/sales2p1/promo/desktop-promo-banner.webp"
                     alt="raingo data promo">
            </div>
        </div>
    </ng-template>

    <ng-template #level100Features>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">the101 pro features</span>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8"
             [ngClass]="isFeatureSpecs === true ? 'dropFeatures' : 'hidden'">
            <div class="img-feature d-flex">
                <img src="/assets/images/sales2p1/the101pro_100.webp" alt="artistic wifi router">
            </div>
            <div class="plan-sub-header ">
                <h3 class="rain-bt">ultra compact design</h3>
                <p class="">210mm x 210mm x 48mm</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">large touch screen</h3>
                <p class="">2.1” circular display, with even more features & content.</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">NEW 101 smart skins</h3>
                <p class="">A built-in NFC brings your screen to life</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">5G advanced</h3>
                <p class="">300 MHz 4-carrier aggregation</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">latest wifi 7</h3>
                <p class="">Higher speed & lower latency for better streaming</p>
            </div>
            <a href="/the101" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <ng-template #xtenderFeatures>
        <div class="plan-dropdown-container">
            <p class="rain-bt"><span class="font-weight-bold">add 101 xtenders</span>&trade;</p>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8 xtender-container"
             [ngClass]="isXtender === true ? 'dropFeatures' : 'hidden'">
            <div class="xtender-feature mb-10 d-flex">
                <img src="/assets/images/xtenders-addon/xtender-home.webp" alt="artistic wifi router">
                <div class="xtender-price-wrapper  d-flex flex-column">
                    <span class="tcs-text">for additional</span>
                    <span class="price-text">R100</span>
                    <span class="tcs-text">month-to-month</span>
                </div>
            </div>
            <div class="plan-sub-header">
                <h3 class="rain-bt">smart mesh wifi</h3>
                <p>A single seamless network in minutes</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">1.8" touch screen</h3>
                <p>Packed with smart features.</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">AX3600 wifi 6</h3>
                <p>For improved speed, range & performance</p>
            </div>
            <div class="plan-sub-header d-flex flex-column">
                <h3 class="rain-bt">scan to connect</h3>
                <p>Forget those long wifi passwords</p>
            </div>
            <a (click)="gotoThe101Xtender()" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <ng-template #xtenderFeatures2>
        <div class="plan-dropdown-container">
            <p class="rain-bt"><span class="font-weight-bold">add 101 xtenders</span>&trade;</p>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8 xtender-container"
             [ngClass]="isXtender === true ? 'dropFeatures' : 'hidden'">
            <div class="xtender-feature mb-10 d-flex">
                <img src="/assets/images/xtenders-addon/xtender-home2.webp" alt="artistic wifi router">
                <div class="xtender-price-wrapper d-flex flex-column">
                    <span class="tcs-text">for additional</span>
                    <span class="price-text">R135</span>
                    <span class="tcs-text">month-to-month</span>
                </div>
            </div>
            <div class="plan-sub-header">
                <h3 class="rain-bt">smart mesh wifi</h3>
                <p>A single seamless network in minutes</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">1.8" touch screen</h3>
                <p>Packed with smart features.</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">AX3600 wifi 6</h3>
                <p>For improved speed, range & performance</p>
            </div>
            <div class="plan-sub-header d-flex flex-column">
                <h3 class="rain-bt">scan to connect</h3>
                <p>Forget those long wifi passwords</p>
            </div>
            <a (click)="gotoThe101Xtender()" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <ng-template #level100Features>
        <div class="plan-dropdown-container">
            <span class="font-weight-bold rain-bt">the101 pro features</span>
        </div>
        <div class="plan-specs d-flex justify-content-center flex-column pt-8"
             [ngClass]="isFeatureSpecs === true ? 'dropFeatures' : 'hidden'">
            <div class="img-feature d-flex">
                <img src="/assets/images/sales2p1/the101pro_100.webp" alt="artistic wifi router">
            </div>
            <div class="plan-sub-header ">
                <h3 class="rain-bt">ultra compact design</h3>
                <p class="">210mm x 210mm x 48mm</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">large touch screen</h3>
                <p class="">2.1” circular display, with even more features & content.</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">NEW 101 smart skins</h3>
                <p class="">A built-in NFC brings your screen to life</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">5G advanced with AI</h3>
                <p class="">300 MHz 4-carrier aggregation</p>
            </div>
            <div class="plan-sub-header d-flex justify-content-center flex-column">
                <h3 class="rain-bt">latest wifi 7</h3>
                <p class="">Higher speed & lower latency for better streaming</p>
            </div>
            <a href="/the101" class="pb-10"><b><u>learn more</u></b></a>
        </div>
    </ng-template>

    <!-- mobile view -->
    <!--    <rain-rain-one-purchase [position]="1"></rain-rain-one-purchase>-->

    <section class="hero-section justify-content-center">
        <div class="hero-container" *ngIf=" (selectedMode$ |async) === 'consumer'">
            <picture>
                <source media="(max-width:600px)" srcset="/assets/images/home-page/hero-section/hero-home-mobi.webp">
                <source media="(max-width:768px)" srcset="/assets/images/home-page/hero-section/hero-home-tablet.webp">
                <img src="/assets/images/home-page/hero-section/home-hero-desktop.webp" alt="" class="home-raingo-asset"
                     style="width: 100%; margin: 0 auto; padding-top: 10px;">
            </picture>
<!--            <span *ngIf="clickToClaimAmount['consumer'] != 0" class="overlay-span" (click)="handleRequestSalesCallFromSticky()"></span>-->
<!--            <picture>-->
<!--                <source media="(max-width:600px)" [srcset]="homeMobile">-->
<!--                <source media="(max-width:768px)" [srcset]="homeTablet">-->
<!--                <img [src]="homeDesktop" alt="" class="home-raingo-asset"-->
<!--                     style="width: 100%; margin: 0 auto; padding-top: 10px;">-->
<!--            </picture>-->
            <div class="hero-header-container hide-for-mobile-screen">
                <img class="logo-svg mb-9" src="/assets/images/home-page/hero-section/rainone-home.svg" alt="">
                <p class="header-text mb-7 fw-300"><span class="fw-5">unlimited</span> 5G home wifi <br> + <span
                        class="fw-5">FREE</span> calls & data for 2 phones</p>
                <div class="month-text d-flex flex-column m-0">
                    <span class="month-text fw-300">from</span>
                    <span class="price-text">R625</span>
                    <span class="month-text fw-300">month-to-month</span>
                </div>
            </div>
        </div>

        <div class="hero-container" *ngIf=" (selectedMode$ |async) === 'sme'">
            <picture>
                <source media="(max-width:600px)" srcset="/assets/images/home-page/hero-section/hero-work-mobi.webp">
                <source media="(max-width:768px)" srcset="/assets/images/home-page/hero-section/hero-work-tablet.webp">
                <img src="/assets/images/home-page/hero-section/work-hero-desktop.webp" alt="" class="home-raingo-asset"
                     style="width: 100%; margin: 0 auto;">
            </picture>
<!--            <span *ngIf="clickToClaimAmount['sme'] != 0" class="overlay-span" (click)="handleRequestSalesCallFromSticky()"></span>-->
<!--            <picture>-->
<!--                <source media="(max-width:600px)" [srcset]="workMobile">-->
<!--                <source media="(max-width:768px)" [srcset]="workTablet">-->
<!--                <img [src]="workDesktop" alt="" class="home-raingo-asset"-->
<!--                     style="width: 100%; margin: 0 auto;">-->
<!--            </picture>-->
            <div class="hero-header-container">
                <div class="hide-for-mobile-screen">
                    <img class="logo-svg mb-9" src="/assets/images/home-page/hero-section/rainone-work.svg" alt="">
                    <p class="header-text mb-7 fw-300"><span class="fw-5">unlimited</span> 5G work wifi <br> + <span
                            class="fw-5">FREE</span> calls & data for 5 phones</p>
                    <div class="month-text d-flex flex-column m-0">
                        <span class="month-text fw-300">from</span>
                        <span class="price-text">R795</span>
                        <span class="month-text fw-300">month-to-month</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="custom-pagination d-flex justify-content-center">
        <span class="sBullet" (click)="bulletNavigation(0)" #bullet1></span>
        <span class="sBullet" (click)="bulletNavigation(1)" #bullet2></span>
        <span class="sBullet" (click)="bulletNavigation(2)" #bullet3></span>
    </div>
    <swiper-container class="product-container"
                      slides-per-view="1.2"
                      space-between="20"
                      auto-height="true"
                      #swiper>
        <swiper-slide class="product-item pl-5">
            <div class="mobi-bundle-grid ">
                <ng-container *ngTemplateOutlet="level30"></ng-container>

                <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level30Plan"></ng-container>
                </div>

                <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level30Features"></ng-container>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide class="product-item">
            <div class="mobi-bundle-grid">
                <ng-container *ngTemplateOutlet="level60"></ng-container>

                <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level60Plan"></ng-container>
                </div>

                <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level60Features"></ng-container>
                </div>

                <div *ngIf="isXtenderWrapper" @fadeInOut
                     class="plan-dropdown-wrapper d-flex justify-content-center flex-column grey-t">
                    <ng-container *ngTemplateOutlet="xtenderFeatures2"></ng-container>
                </div>
            </div>
        </swiper-slide>
        <swiper-slide class="product-item pr-5">
            <div class="mobi-bundle-grid ">
                <ng-container *ngTemplateOutlet="level100"></ng-container>

                <div class="plan-dropdown-wrapper d-flex justify-content-center flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level100Plan"></ng-container>
                </div>

                <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                    <ng-container *ngTemplateOutlet="level100Features"></ng-container>
                </div>
                <div *ngIf="isXtenderWrapper" @fadeInOut
                     class="plan-dropdown-wrapper d-flex justify-content-center flex-column grey-t">
                    <ng-container *ngTemplateOutlet="xtenderFeatures"></ng-container>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>

    <!------ desktop view ----->
    <section>

        <div class="container">
            <div class="row">
                <div class="col-4 d-flex justify-content-center">
                    <div class="base-bundle-wrapper">
                        <ng-container *ngTemplateOutlet="level30"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="mid-bundle-wrapper">
                        <ng-container *ngTemplateOutlet="level60"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="premium-bundle-wrapper">
                        <ng-container *ngTemplateOutlet="level100"></ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level30Plan"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level60Plan"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level100Plan"></ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level30Features"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level60Features"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="level100Features"></ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 d-flex justify-content-center offset-md-4 offset-sm-4">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="xtenderFeatures2"></ng-container>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <div class="plan-dropdown-wrapper d-flex flex-column grey-t">
                        <ng-container *ngTemplateOutlet="xtenderFeatures"></ng-container>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="rainGo-ge-force-section">
        <div class="rainGO-ge-force-wrapper" style="max-width: 1300px; margin: 0 auto;">


            <rain-product-three-tiles *ngIf=" (selectedMode$ |async) === 'sme'">
                <div content1 class="">
                    <div class="img-container">
                        <picture>
                            <source media="(max-width:600px)"
                                    srcset="assets/images/three-card-tile/mobile-product-4/learn-more-noxtender-mobi.webp">
                            <source media="(max-width:991px)"
                                    srcset="assets/images/three-card-tile/tablet-product-4/learn-more-noxtender-tablet.webp">
                            <img class="home-page-image bs-101 br-8"
                                 src="assets/images/three-card-tile/desktop-product-4/learn-more-noxtender-desktop.webp"
                                 alt="rainone home unlimited 5g wifi">
                        </picture>
                        <button class="btn d-block btn-md btn-primary mb-10 mt-3" (click)="gotoThe101()">
                            learn more
                        </button>
                    </div>
                </div>

                <div content2 class="img-container">
<!--                    <span *ngIf="clickToClaimAmount['consumer'] != 0" class="overlay-span-tile" (click)="handleRequestSalesCallFromSticky()"></span>-->
<!--                    <picture>-->
<!--                        <source media="(max-width:600px)"-->
<!--                                [srcset]="homeMobileTile">-->
<!--                        <source media="(max-width:991px)"-->
<!--                                [srcset]="homeTabletTile">-->
<!--                        <img class="home-page-image br-8"-->
<!--                             [src]="homeDesktopTile"-->
<!--                             alt="rainone home unlimited 5g wifi">-->
<!--                    </picture>-->
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="assets/images/three-card-tile/mobile-product-4/home-mobi.webp">
                        <source media="(max-width:991px)"
                                srcset="assets/images/three-card-tile/tablet-product-4/home-tablet.webp">
                        <img class="home-page-image br-8"
                             src="assets/images/three-card-tile/desktop-product-4/home-desktop.webp"
                             alt="rainone home unlimited 5g wifi">
                    </picture>
                    <button class="btn d-block btn-md btn-primary mb-10 mt-3" (click)="homeNavigation()">
                        buy now
                    </button>
                </div>


                <div content3 class="img-container">
                    <span class="overlay-span-mobile-tile" (click)="handleRequestSalesCallFromSticky()"></span>
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="assets/images/three-card-tile/mobile-product-4/mobile-mobi.webp">
                        <source media="(max-width:991px)"
                                srcset="assets/images/three-card-tile/tablet-product-4/mobile-tablet.webp">
                        <img class="home-page-image br-8"
                             src="assets/images/three-card-tile/desktop-product-4/mobile-desktop.webp"
                             alt="rainone home unlimited 5g wifi">
                    </picture>
                    <button class="d-block btn-primary mb-10 mt-3" (click)="mobileNavigation()">
                        buy now
                    </button>
                </div>
            </rain-product-three-tiles>

            <rain-product-three-tiles *ngIf="(selectedMode$ |async) === 'consumer'">
                <div content1 class="">
                    <div class="img-container">
                        <picture>
                            <source media="(max-width:600px)"
                                    srcset="assets/images/three-card-tile/mobile-product-4/learn-more-noxtender-mobi.webp">
                            <source media="(max-width:991px)"
                                    srcset="assets/images/three-card-tile/tablet-product-4/learn-more-noxtender-tablet.webp">
                            <img class="home-page-image bs-101 br-8"
                                 src="assets/images/three-card-tile/desktop-product-4/learn-more-noxtender-desktop.webp"
                                 alt="rainone home unlimited 5g wifi">
                        </picture>
                        <button class="d-block btn-primary mb-10 mt-3" (click)="gotoThe101()">
                            learn more
                        </button>
                    </div>
                </div>

                <div content2 class="img-container">
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="assets/images/three-card-tile/mobile-product-4/work-mobi.webp">
                        <source media="(max-width:991px)"
                                srcset="assets/images/three-card-tile/tablet-product-4/work-tablet.webp">
                        <img class="home-page-image br-8"
                             src="assets/images/three-card-tile/desktop-product-4/work-desktop.webp"
                             alt="rainone home unlimited 5g wifi">
                    </picture>
<!--                    <span *ngIf="clickToClaimAmount['sme'] != 0" class="overlay-span-tile" (click)="handleRequestSalesCallFromSticky()"></span>-->
<!--                    <picture>-->
<!--                        <source media="(max-width:600px)"-->
<!--                                [srcset]="workMobileTile">-->
<!--                        <source media="(max-width:991px)"-->
<!--                                [srcset]="workTabletTile">-->
<!--                        <img class="home-page-image br-8"-->
<!--                             [src]="workDesktopTile"-->
<!--                             alt="rainone home unlimited 5g wifi">-->
<!--                    </picture>-->
                    <button class="d-block btn-primary mb-10 mt-3" (click)="workNavigation()">
                        buy now
                    </button>
                </div>

                <div content3 class="img-container">
                    <span class="overlay-span-mobile-tile" (click)="handleRequestSalesCallFromSticky()"></span>
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="assets/images/three-card-tile/mobile-product-4/mobile-mobi.webp">
                        <source media="(max-width:991px)"
                                srcset="assets/images/three-card-tile/tablet-product-4/mobile-tablet.webp">
                        <img class="home-page-image br-8"
                             src="assets/images/three-card-tile/desktop-product-4/mobile-desktop.webp"
                             alt="rainone home unlimited 5g wifi">
                    </picture>
                    <button class="d-block btn-primary mb-10 mt-3" (click)="mobileNavigation()">
                        buy now
                    </button>
                </div>
            </rain-product-three-tiles>


            <div *ngIf=" (selectedMode$ |async) === 'consumer'" class="rainGo-ge-force-grid-container">
                <div class="rainGo-container">
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="/assets/images/home-page/raingo-nvidia/mobile/raingo-home-mobi.webp">
                        <source media="(max-width:768px)"
                                srcset="/assets/images/home-page/raingo-nvidia/tablet/raingo-home-tablet.webp">
                        <img src="/assets/images/home-page/raingo-nvidia/desktop/raingo-home-desktop.webp" alt=""
                             class="home-raingo-asset">
                    </picture>
                    <a (click)="gotoTerms()">*Ts & Cs apply</a>
                </div>
                <div class="ge-force-container">
                    <picture>
                        <source media="(max-width:600px)"
                                srcset="/assets/images/home-page/raingo-nvidia/mobile/nvidia-mobi.webp">
                        <source media="(max-width:768px)"
                                srcset="/assets/images/home-page/raingo-nvidia/tablet/nvidia-tablet.webp">
                        <img src="/assets/images/home-page/raingo-nvidia/desktop/nvidia-desktop.webp" alt=""
                             style="width: 100%;">
                    </picture>
                    <div class="join-button-container">
                        <button class="join-now-btn" (click)="routeToNvidia()">join now</button>
                    </div>
                </div>
            </div>
            <div *ngIf=" (selectedMode$ |async) === 'sme'" class="rainGo-tile-work">
                <picture>
                    <source media="(max-width:600px)"
                            srcset="/assets/images/home-page/raingo-nvidia/mobile/raingo-work-mobi.webp">
                    <source media="(max-width:768px)"
                            srcset="/assets/images/home-page/raingo-nvidia/tablet/raingo-work-tablet.webp">
                    <img src="/assets/images/home-page/raingo-nvidia/desktop/raingo-work-desktop.webp" alt=""
                         style="width: 100%;">
                </picture>
                <a (click)="gotoTerms()">*Ts & Cs apply</a>
            </div>
        </div>
    </section>

    <!-- <ask-rain-button></ask-rain-button> -->
</ng-container>